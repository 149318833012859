import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "WebLayout" */ "@/layouts/WebLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false,
          title: "Home - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/registro-publico-ley-21.146",
        name: "RegistroPublico",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Comuna/RegistroPublicoView.vue"
          ),
        meta: {
          requiresAuth: false,
          title:
            "Regístro Público Ley 21.146 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/organizaciones-comunitarias",
        name: "OrganizacionesComunitarias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Comuna/OrganizacionesComunitariasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title:
            "Regístro Público Ley 21.146 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/elecciones",
        name: "Elecciones",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Comuna/EleccionesView.vue"
          ),
        meta: {
          requiresAuth: false,
          title:
            "Regístro Público Ley 21.146 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Noticias/NoticiasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Noticias - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/noticias/publicacion/:nombre",
        name: "Publicacion",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Noticias/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia"
        }
      },
      {
        path: "/contenido/noticias/preview/:nombre",
        name: "PublicacionPreview",
        component: () =>
          import(
            /* webpackChunkName: "PublicacionPreview" */ "@/views/Noticias/PublicacionPreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia Preview"
        }
      },
      {
        path: "/noticias/categoria/:nombre",
        name: "Categoria",
        component: () =>
          import(
            /* webpackChunkName: "Categoria" */ "@/views/Noticias/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias"
        }
      },
      {
        path: "/:nombre",
        name: "Pagina",
        component: () =>
          import(
            /* webpackChunkName: "Pagina" */ "@/views/Pagina/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/contenido/pagina/preview/:nombre",
        name: "PaginaPreview",
        component: () =>
          import(
            /* webpackChunkName: "PaginaPreview" */ "@/views/Pagina/PreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Preview Página"
        }
      },
      {
        path: "/municipio/servicios",
        name: "Servicios",
        component: () =>
          import(
            /* webpackChunkName: "Servicios" */ "@/views/Servicios/ServiciosView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Servicios y trámites - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/avisos",
        name: "Avisos",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Servicios/AvisosView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Avisos Municipales - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/oirs",
        name: "FormularioOirs",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/OirsView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Formulario OIRS - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/carnaval",
        name: "FormularioCarnaval",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/CarnavalView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Formulario Carnaval 2024- Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/cultura",
        name: "FormularioCultura",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/CulturaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Formulario Postulación a Talleres 2024 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/festival",
        name: "FormularioFestival",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/FestivalView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Ficha de Inscripción XLIII Festival Folclórico en la patagonia 2024 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/pre-festival",
        name: "FormularioPreFestival",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/PreFestivalView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Ficha de Inscripción XXX Pre-Festival Folclórico en la patagonia 2024 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/chapuzon",
        name: "FormularioChapuzon",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/ChapuzonView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Chapuzón del Estrecho 2024 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/chapuzonKids",
        name: "FormularioChapuzon2024",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/Chapuzon2024View.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Chapuzón del Estrecho 2024 - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/2024/Navidad",
        name: "FormularioNavidad",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/NavidadView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Postulación Navidad - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/denuncia",
        name: "FormularioDenuncia",
        component: () =>
          import(
            /* webpackChunkName: "denuncias" */ "@/views/Formularios/DenunciaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Formulario de Denuncias - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/formulario/personalidad-juridica",
        name: "FormularioPersonalidadJuridica",
        component: () =>
          import(
            /* webpackChunkName: "personalidad" */ "@/views/Formularios/PersonalidadJuridicaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Formulario Certificado de Personalidad Jurídica - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/oficinas-y-telefonos-municipales",
        name: "Telefonos",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Web/TelefonosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Oficinas y Teléfonos - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/fleteros",
        name: "Fleteros",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Web/FleterosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Fletes - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/ofertas",
        name: "OfertasOmil",
        component: () =>
          import(
            /* webpackChunkName: "oferta" */ "@/views/Web/OfertasOmilView.vue"
          ),
        meta: {
          title: "Ofertas Laborales - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/cuenta/eliminar",
        name: "EliminarCuenta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Web/EliminarCuentaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/encuesta/:nombre",
        name: "Encuesta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Encuesta/EncuestaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/municipio/concejo",
        name: "Concejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcejoView.vue"
          ),
        meta: {
          title: "Concejo Municipal - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/actas-concejo",
        name: "ActasConcejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ActasConcejoView.vue"
          ),
        meta: {
          title: "Actas de Concejo - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/ordenanzas",
        name: "Ordenanzas",
        component: () =>
          import(
            /* webpackChunkName: "ordenanzas" */ "@/views/Municipalidad/OrdenanzasView.vue"
          ),
        meta: {
          title: "Ordenanzas - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/informes-financieros",
        name: "Informes",
        component: () =>
          import(
            /* webpackChunkName: "informes" */ "@/views/Municipalidad/InformesView.vue"
          ),
        meta: {
          title: "Informes Financieros - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/cosoc",
        name: "Cosoc",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Municipalidad/CosocView.vue"
          ),
        meta: {
          title:
            "Consejo Comunal de Organizaciones de la Sociedad Civil - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/municipio/organigrama",
        name: "Organigrama",
        component: () =>
          import(
            /* webpackChunkName: "OrganigramaMuni" */ "@/views/Municipalidad/OrganigramaView.vue"
          ),
        meta: {
          title: "Organigrama Municipal - Ilustre Municipalidad de Punta Arenas"
        }
      },
      {
        path: "/reserva/:nombre",
        name: "Reserva",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/ReservaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/reserva/ticket/:codigo",
        name: "ReservaTicket",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/RevisarReservaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/chapuzon/2024/ticket/:codigo",
        name: "ReservaChapuzon",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/RevisarReservaChapuzonView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/chapuzonkids/2024/ticket/:codigo",
        name: "ReservaChapuzon2024",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/RevisarReservaChapuzon2024View.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/navidad/2024/ticket/:codigo",
        name: "ReservaNavidad",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/RevisarReservaNavidadView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/reserva/:nombre/anular",
        name: "AnularReserva",
        component: () =>
          import(
            /* webpackChunkName: "AnularReserva" */ "@/views/Reserva/AnularReservaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/municipio/reserva",
        name: "ListaReservas",
        component: () =>
          import(
            /* webpackChunkName: "ListaReservas" */ "@/views/Reserva/ListaReservasView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/municipio/esterilizacion",
        name: "Fleteros",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Formularios/EsterilizacionView.vue"
          ),
        meta: {
          requiresAuth: false,
          title:
            "Formulario de inscripción esterilización canina y felina - Ilustre Municipalidad de Punta Arenas"
        }
      },
      { path: "/:pathMatch(.*)", redirect: { path: "/" } }
      // Legacy routes
      // {
      //   path: "/tramites-y-pagos-online",
      //   name: "Tramites",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "tramites" */ "@/views/Web/TramitesView.vue"
      //     ),
      //   meta: {
      //     requiresAuth: false,
      //     title: "Trámites y pagos online - Ilustre Municipalidad de Punta Arenas"
      //   }
      // },
      // {
      //   path: "/galerias",
      //   name: "Galerias",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "galerias" */ "@/views/Galeria/GaleriaView.vue"
      //     ),
      //   meta: {
      //     requiresAuth: false,
      //     title: "Galerías - Ilustre Municipalidad de Punta Arenas"
      //   }
      // },
      // {
      //   path: "/galeria/:nombre",
      //   name: "DetalleGaleria",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "detallegaleria" */ "@/views/Galeria/DetalleGaleriaView.vue"
      //     ),
      //   meta: {
      //     requiresAuth: false,
      //     title: "Galeria"
      //   }
      // },
      // {
      //   path: "/destacado/:nombre",
      //   name: "Destacado",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Destacado" */ "@/views/Destacado/GenericoView.vue"
      //     ),
      //   meta: {
      //     notLogged: false,
      //     requiresAuth: false,
      //     title: "Destacado"
      //   }
      // },
      // {
      //   path: "/contacto/formulario",
      //   name: "Contacto",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "contacto" */ "@/views/Contacto/ContactoView.vue"
      //     ),
      //   meta: {
      //     title: "Formulario de Consultas - Ilustre Municipalidad de Punta Arenas"
      //   }
      // },
      // {
      //   path: "/organizaciones/ley-21146",
      //   name: "Organizaciones",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "organizaciones" */ "@/views/Municipalidad/OrganizacionesView.vue"
      //     ),
      //   meta: {
      //     title: "Ley 21.146 - Ilustre Municipalidad de Punta Arenas"
      //   }
      // },
      // {
      //   path: "/category/noticia/",
      //   redirect: "/noticias"
      // },
      // { path: "/wp-content/*", redirect: "/" },
      // { path: "/category/*", redirect: "/" },
      // { path: "/tag/*", redirect: "/" }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  next();
  if ("speechSynthesis" in window) {
    window.speechSynthesis.cancel();
  }
});

export default router;
